<template>
  <section>
    <title-section
      title="Agregar nueva línea"
      returnText="Regresar"
      :returnToFunction="returnPrevView"
    >
      <template>
        <div class="level-item">
          <button class="button button_micro_dark_outlined" @click="returnPrevView" :disabled="loading">
            CANCELAR
          </button>
        </div>
        <div class="level-item">
          <button
            class="button button_micro_dark"
            :disabled="loading"
            :class="{ 'is-loading' : loading }"
            @click="createLineAction()"
          >
            AGREGAR
          </button>
        </div>
      </template>
    </title-section>
    <line-main-add
      ref="lineMainRef"
      :line="line"
      @set-image="setImage"
    />
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LineAdd',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    LineMainAdd: () => import('@/components/Products/Lines/LineMainAdd.vue')
  },
  data () {
    return {
      line: {
        parentLineName: null,
        status: false,
        coverImage: null
      },
      imageFile: null,
      loading: false
    }
  },
  methods: {
    ...mapActions(['returnPrevView', 'createProductLine']),
    setImage (event) {
      this.imageFile = event
    },
    async createLineAction () {
      if (await this.$validator.validateAll()) {
        this.loading = true
        // Se obtienen los datos del componente hijo
        const lineMainData = await this.$refs.lineMainRef.getLocalData()
        // Se crea la nueva estructura de datos para el envio hacia el API
        const dataCreate = {
          parentLineName: lineMainData.parentLineName,
          subLinesList: lineMainData.subLinesList,
          image: this.imageFile,
          active: lineMainData.active
        }
        const resp = await this.createProductLine(dataCreate)
        if (resp) {
          this.returnPrevView()
        }
        this.loading = false
      }
    }
  },
  async beforeMount () {
  }
}
</script>

<style lang="scss" scoped>
  .button {
    font-size: 14px;
    width: 150px;
  }
</style>
